import React, { useState, useEffect } from 'react';
import { Container, Typography, RadioGroup, FormControlLabel, Radio, Button, Box } from '@mui/material';

const quizData = {
    '科学': [
        { question: '地球是平的吗？', options: ['否', '是'], correct: '否' },
        { question: '水的化学式是什么？', options: ['CO2', 'H2O', 'NaCl'], correct: 'H2O' },
        { question: '人体最大的器官是？', options: ['皮肤', '心脏', '大脑'], correct: '皮肤' },
        // 可以继续添加更多科学问题...
    ],
    '数学': [
        { question: '2 + 2 等于多少？', options: ['4', '22', '2'], correct: '4' },
        { question: '一个直角三角形的斜边长称为什么？', options: ['周长', '面积', '斜边'], correct: '斜边' },
        { question: '圆的面积公式是什么？', options: ['πr^2', '2πr', 'πd'], correct: 'πr^2' },
        // 可以继续添加更多数学问题...
    ],
    // 添加其他类别...
};

function Quiz({ category, onReset }) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [score, setScore] = useState(0);
    const questions = quizData[category] || [];

    useEffect(() => {
        setCurrentQuestionIndex(0);
        setSelectedAnswer('');
        setScore(0);
    }, [category]);

    const handleNext = () => {
        const isCorrect = questions[currentQuestionIndex].correct === selectedAnswer;
        if (isCorrect) {
            setScore(score + 1);
        }

        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questions.length) {
            setCurrentQuestionIndex(nextQuestionIndex);
            setSelectedAnswer('');
        } else {
            alert(`测验完成! 你的得分是: ${score + (isCorrect ? 1 : 0)} / ${questions.length}`);
            onReset();
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="h4" gutterBottom component="div" sx={{ mb: 4 }}>
                {category} 测验
            </Typography>
            <Box sx={{ mb: 3 }}>
                <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                    问题 {currentQuestionIndex + 1} / {questions.length}
                </Typography>
                <Typography variant="h6" sx={{ mb: 3 }}>
                    {questions[currentQuestionIndex]?.question}
                </Typography>
                <RadioGroup
                    name="quiz-options"
                    value={selectedAnswer}
                    onChange={(e) => setSelectedAnswer(e.target.value)}
                >
                    {questions[currentQuestionIndex]?.options.map((option, index) => (
                        <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
            </Box>
            <Button variant="contained" sx={{ mr: 2 }} onClick={handleNext}>
                {currentQuestionIndex === questions.length - 1 ? '提交' : '下一题'}
            </Button>
            <Button variant="outlined" onClick={onReset}>
                返回类别选择
            </Button>
        </Container>
    );
}

export default Quiz;
