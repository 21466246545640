import React, { useState } from 'react';
import Quiz from './Quiz';
import { Container, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';

function App() {
    const [selectedCategory, setSelectedCategory] = useState('');

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
            {!selectedCategory ? (
                <>
                    <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
                        Profile self check
                    </Typography>
                    <RadioGroup
                        aria-label="quiz-category"
                        name="quiz-category"
                        value={selectedCategory}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="科学" control={<Radio />} label="科学" />
                        <FormControlLabel value="数学" control={<Radio />} label="数学" />
                        {/* 可以根据需要添加更多类别 */}
                    </RadioGroup>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => setSelectedCategory(selectedCategory)}>
                        确定
                    </Button>
                </>
            ) : (
                <Quiz category={selectedCategory} onReset={() => setSelectedCategory('')} />
            )}
        </Container>
    );
}

export default App;
